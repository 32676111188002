
















import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import coreApiWorkerClient from "@/services/apis/coreApiWorkerClient";
import makeSelectOptions from "@common/util/makeSelectOptions";
import orderLocationStatuses from "@/constants/orderLocationStatuses";
import userManager from "@/services/userManager";
import DataContainer from "@vuetifyx/common/DataContainer";
import copy from "copy-to-clipboard";

export default Vue.extend({
  data() {
    return {
      self: this,
      market: null,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions() {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
          "show-select": true,
        },
        content: {
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          displayFields: {
            orderId: {
              text: "ID",
              sortable: true,
              width: "100px",
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            customerMessage: {
              text: "Lời nhắn",
              sortable: true,
            },
            customerState: !userManager.checkRole(["content", "design"], true) && {
              text: "State",
            },
            customerCity: !userManager.checkRole(["content", "design"], true) && {
              text: "City",
            },
            customerDistrict: !userManager.checkRole(["content", "design"], true) && {
              text: "District",
            },
            customerWard: !userManager.checkRole(["content", "design"], true) && {
              text: "Ward",
            },
            customerAddress: !userManager.checkRole(["content", "design"], true) && {
              text: "Address",
            },
            customerPostalCode: !userManager.checkRole(["content", "design"], true) && {
              text: "Postal Code",
            },
            locationUser: {
              text: "User Check địa chỉ",
              options: {
                subProp: "locationUser.name",
                sortBy: "locationUserId",
                attrs(_, item) {
                  const attrs: any = {};
                  if (item.locationUser && item.locationUser.ht === "nv") {
                    attrs.class = "red--text";
                  }
                  return attrs;
                },
              },
            },
            locationStatus: {
              text: "TT Địa chỉ",
              sortable: true,
              options: {
                label(value) {
                  return orderLocationStatuses[value || ""];
                },
              },
            },
            locationNote: {
              text: "Ghi chú địa chỉ",
            },
            action: {},
          },
          filters: {
            colLength: 2,
            filters: {
              orderId: {
                attrs: {
                  label: "Mã đơn hàng",
                },
                rules: {
                  equal_to: {},
                },
              },
              createdTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày tạo",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (bắt đầu)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (kết thúc)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              blank1: { ext: { blank: 3 } },
              customerName: {
                attrs: {
                  label: "Tên KH",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerEmail: {
                attrs: {
                  label: "Email",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerPhone: {
                attrs: {
                  label: "SĐT",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerMessage: {
                attrs: {
                  label: "Lời nhắn",
                },
                rules: {
                  equal_to: {},
                },
              },
              blank2: { ext: { blank: 2 } },
              locationUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User Check địa chỉ",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return [
                          {
                            _id: "",
                            name: "Trống",
                          },
                        ].concat(items);
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              locationStatus: {
                type: "selectMultiple",
                attrs: {
                  label: "Trạng thái địa chỉ",
                  items: [{ value: "", text: "Trống" }].concat(makeSelectOptions(orderLocationStatuses)),
                },
                rules: {
                  in: {},
                },
              },
              locationNote: {
                attrs: {
                  label: "Ghi chú địa chỉ",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerMessageExist: {
                type: "select",
                attrs: {
                  label: "Có lời nhắn",
                  items: [
                    { value: "", text: "Trống" },
                    { value: "yes", text: "Có" },
                    { value: "no", text: "Không" },
                  ],
                },
                rules: {
                  custom: {
                    filterMaker(value) {
                      if (value === "yes") {
                        return [
                          {
                            key: "customerMessage",
                            operator: "not_equal_to",
                            value: "",
                          },
                          {
                            key: "customerMessage",
                            operator: "has_property",
                          },
                        ];
                      } else if (value === "no") {
                        return {
                          operator: "or",
                          subFilters: [
                            {
                              key: "customerMessage",
                              operator: "equal_to",
                              value: "",
                            },
                            {
                              key: "customerMessage",
                              operator: "not_has_property",
                            },
                          ],
                        };
                      }
                      return [];
                    },
                  },
                },
              },
            },
          },
          topActionButtons: {
            export: userManager.checkRole(["kt", "vdl", "vd"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (findAllOptions.extraParams) {
                    findAllOptions.extraParams = {};
                  }
                  findAllOptions.extraParams.fieldSet = "cdc";

                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                        extraParams: {
                          fieldSet: "cdc",
                        },
                      }),
                    };
                  }

                  coreApiWorkerClient.download("orders", `@/export`, findAllOptions);
                },
              },
            },
          },
          multiSelectActionButtons: {
            selectLocationUserId: userManager.checkRole(["kt", "cdc", "vdl", "vd"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Chọn user check địa chỉ",
                icon: "mdi-map-marker",
              },
              target: {
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Chọn user check địa chỉ",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  locationUserId: (userManager.checkRole(["cdc"], true) && {
                                    type: "select",
                                    attrs: {
                                      label: "User Check địa chỉ",
                                      items: [
                                        {
                                          text: userManager.getUserInfo().name,
                                          value: userManager.getUserInfo()._id,
                                        },
                                      ],
                                      readonly: true,
                                    },
                                    ext: {
                                      defaultValue: userManager.getUserInfo()._id,
                                    },
                                  }) || {
                                    type: "XAutocomplete",
                                    attrs: {
                                      label: "User Check địa chỉ",
                                      required: true,
                                      "item-value": "_id",
                                      "item-text": "name",
                                      xOptions: {
                                        content: {
                                          async itemsSuggestor(search, value, limit) {
                                            const { items } = await coreApiClient.call("users", "findAll", {
                                              payload: JSON.stringify({
                                                limit,
                                                search,
                                                filters: [
                                                  {
                                                    key: "roles",
                                                    operator: "equal_to",
                                                    value: "cdc",
                                                  },
                                                  {
                                                    key: "_id",
                                                    operator: "equal_to",
                                                    value,
                                                  },
                                                ],
                                              }),
                                            });
                                            return items;
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Cập nhật",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            const ids = dataTable.model.value.map((item) => item._id);
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call(
                                  "orders",
                                  "updateMany",
                                  {},
                                  {
                                    ids,
                                    data: {
                                      locationUserId: data.locationUserId,
                                    },
                                    updateRequest: {
                                      scenario: "LocationCheckSetLocationUserId",
                                    },
                                  }
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            setHt: userManager.checkRole(["kt", "vdl", "vd", "cdc"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Chuyển trạng thái hoàn thành",
                icon: "mdi-check",
              },
              target: {
                confirm: {
                  content: {
                    content: {
                      html: "Xác nhận chuyển trạng thái hoàn thành cho các đơn được chọn?",
                    },
                  },
                  on: {
                    async xYes({ self: dialog }) {
                      const yesBtn = dialog.yesBtnContainer.value;
                      const btn = dialog.context();
                      const dataTable = btn.context();
                      const ids = dataTable.model.value.map((item) => item._id);
                      yesBtn.loading.value = true;
                      const result = await dataTable.execute(
                        async () => {
                          return await coreApiClient.call(
                            "orders",
                            "updateMany",
                            {},
                            {
                              ids,
                              data: {
                                locationStatus: "ht",
                              },
                              updateRequest: {
                                scenario: "LocationCheckSetLocationStatusHt",
                              },
                            }
                          );
                        },
                        undefined,
                        {
                          disableLoading: true,
                          willRefresh: true,
                        }
                      );
                      yesBtn.loading.value = false;
                      if (result) {
                        dialog.hide();
                      }
                    },
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "cdc", "vd", "vdl"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "orderId",
                    },
                  },
                },
              },
              copyLocation: {
                content: {
                  icon: "mdi-content-copy",
                },
                on: {
                  xClick({ self }) {
                    const { item, dataTable } = self.context();
                    const locationItemCandidates = [
                      item.customerState,
                      item.customerCity,
                      item.customerDistrict,
                      item.customerWard,
                      item.customerAddress,
                    ];
                    const locationItems = [];
                    for (const locationItem of locationItemCandidates) {
                      if (locationItem) {
                        locationItems.push(locationItem);
                      }
                    }
                    const text = locationItems.join(" ");
                    copy(text);
                    dataTable.info.value = "Copy địa chỉ thành công";
                  },
                },
                target: {
                  tooltip: { content: { text: "Copy địa chỉ" } },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "marketId",
              operator: "equal_to",
              value: this.context().$route.params.marketId,
            });
            options.filters.push({
              key: "status",
              operator: "in",
              value: ["kt", "cpu", "cxk"],
            });
            options.filters.push({
              key: "locationStatus",
              operator: "in",
              value: ["kt", "sdc", "odz", "k"],
            });
            options.filters.push({
              key: "invalid",
              operator: "equal_to",
              value: false,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("orders", "findAll", options);
              return [items, count];
            },
            async update(item) {
              return await coreApiClient.call(
                "orders",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                  updateRequest: {
                    scenario: "LocationCheckItemUpdate",
                  },
                }
              );
            },
          },
          default: {
            defaultInputs: {
              locationUserId: (userManager.checkRole(["cdc"], true) && {
                type: "select",
                attrs: {
                  label: "User Check địa chỉ",
                  items: [
                    {
                      text: userManager.getUserInfo().name,
                      value: userManager.getUserInfo()._id,
                    },
                  ],
                  readonly: true,
                },
                ext: {
                  defaultValue: userManager.getUserInfo()._id,
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return !item.locationUserId;
                  },
                },
              }) || {
                type: "XAutocomplete",
                attrs: {
                  label: "User Check địa chỉ",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "roles",
                                operator: "equal_to",
                                value: "cdc",
                              },
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              locationStatus: {
                type: "select",
                attrs: {
                  label: "Trạng thái địa chỉ",
                  items: makeSelectOptions(orderLocationStatuses),
                },
                ext: {
                  defaultValue: "",
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return item.locationUserId;
                  },
                },
              },
              locationNote: {
                type: "textLarge",
                attrs: {
                  label: "Ghi chú địa chỉ",
                },
                ext: {
                  condition(form) {
                    const dialog = form.context();
                    const btn = dialog.context();
                    const { item } = btn.context();
                    return item.locationUserId;
                  },
                },
              },
            },
            editForm: userManager.checkRole(["kt", "cdc", "vdl"]) && {
              content: {
                fieldNames: ["locationUserId", "locationStatus", "locationNote"],
              },
            },
          },
        },
      };
    },
  },
  async created() {
    this.market = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
    this.tableOptions = this.makeTableOptions();
  },
});
